import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What do you want to do with your Sketch design?"
    gridify={true}
    options={[
      {
        label: "Redesign everything from scratch in another software",
        link: "/app/digital-product-design/hi-fi/design-from-scratch/"
      },
      {
        label: "Import and use the Sketch components for a new project",
        link: "/app/tool/uizard/"
      },
      {
        label: "Import the file for my operating system",
        link: "/app/digital-product-design/hi-fi/operating-system/"
      },
      {
        label: "Just add interactions over each artboard",
        link: "/app/tool/invision/"
      }
  ]}/>
)

export default Picker;


